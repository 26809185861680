import { useState, useCallback, useEffect, useRef } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	type PrefetchedConsentPreferences,
	shouldRenderBannerPrefetched,
} from '@atlassian/browser-storage-controls';

import { ssrBannerManager } from '@confluence/ssr-utilities';
import type { BannerStateContainer } from '@confluence/banners';

import { MIN_BANNER_HEIGHT, BANNER_NAME } from './constants';

declare global {
	interface Window {
		__COOKIE_CONSENT_PREFERENCES__?: PrefetchedConsentPreferences | null;
	}
}

export function useCookiesConsentBanner(
	bannerState?: BannerStateContainer | undefined,
	bannerRef?: React.RefObject<HTMLDivElement | null>,
) {
	const cookiesDialogRef = useRef<HTMLDivElement | null>(null);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [height, setHeight] = useState(MIN_BANNER_HEIGHT);
	const prefetchedData = Object.keys(window?.__COOKIE_CONSENT_PREFERENCES__ || {}).length
		? window.__COOKIE_CONSENT_PREFERENCES__
		: undefined;

	let shouldRenderInitial: boolean | null = null;
	if (process.env.REACT_SSR) {
		// TODO fix hacky ternary because of type issue
		// If there is no data, it may be because there is not a userId. Defer to package internal logic to determine whether to render
		shouldRenderInitial = prefetchedData
			? shouldRenderBannerPrefetched(prefetchedData)
			: shouldRenderBannerPrefetched();
		if (shouldRenderInitial) {
			ssrBannerManager.registerBanner({
				name: BANNER_NAME,
				height: MIN_BANNER_HEIGHT,
			});
		}
	}
	const [shouldRender, setShouldRender] = useState<boolean | null>(shouldRenderInitial);

	if (shouldRender) {
		bannerState?.show(BANNER_NAME, height);
	} else if (shouldRender === false) {
		bannerState?.hide(BANNER_NAME);
	}

	const findCookiesDialog = useCallback(() => {
		if (cookiesDialogRef?.current) {
			return cookiesDialogRef?.current;
		}

		if (!bannerRef?.current) {
			return null;
		}
		const childDivs = bannerRef?.current.getElementsByTagName('div');
		let cookiesDialog = null;
		for (const element of Object.values(childDivs)) {
			if (!element.offsetHeight) {
				continue;
			}
			// @ts-ignore - Type 'HTMLDivElement' is not assignable to type 'null'
			// Using `ts-ignore` and not `ts-expect-error` here as TypeScript will fail on this line without `ts-ignore` but with `ts-expect-error`
			// This error was introduced after upgrading to TypeScript 5
			cookiesDialog = element;
			break;
		}
		cookiesDialogRef.current = cookiesDialog;
		return cookiesDialog;
	}, [bannerRef]);

	useEffect(() => {
		if (shouldRender) {
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				action: 'rendered',
				actionSubject: 'cookiesConsentBanner',
			}).fire();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldRender]);

	const onWidthChange = useCallback(() => {
		const cookiesDialog = findCookiesDialog();
		if (!cookiesDialog) {
			return;
		}
		setHeight(cookiesDialog.offsetHeight);
	}, [findCookiesDialog]);

	const showBanner = useCallback(() => {
		setShouldRender(true);
		onWidthChange();
	}, [onWidthChange]);

	const hideBanner = useCallback(() => {
		setShouldRender(false);
	}, []);

	return {
		shouldRender,
		showBanner,
		hideBanner,
		onWidthChange,
		prefetchedData,
	};
}
